import './front.scss';

$(document).ready(function () {
    let content = $('.category-description');
    let contentManufacturer = $('#manufacturer');
    if ($(content).length || $(contentManufacturer).length) {
        let readContent = $('.read_content');
        $(document).on({
            click: function () {
                $(this).hide();
                $(readContent).find('.read_less').show();
                $('.truncate').hide()
                $('.notruncate').show();
            }
        }, '.read_content .read_more');
        $(document).on({
            click: function () {
                $(this).hide();
                $(readContent).find('.read_more').show();
                $('.truncate').show()
                $('.notruncate').hide();
            }
        }, '.read_content .read_less');
    }
});