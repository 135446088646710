import './front.scss';
import Splide from '@splidejs/splide';
import Cookies from 'js-cookie';
import '@splidejs/splide/dist/css/splide.min.css';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';

$(document).ready(function () {
    let content = $('.category-description');
    let contentManufacturer = $('#manufacturer');
    if ($(content).length || $(contentManufacturer).length) {
        let readContent = $('.read_content');
        $(document).on({
            click: function () {
                $(this).hide();
                $(readContent).find('.read_less').show();
                $('.truncate').hide()
                $('.notruncate').show();
            }
        }, '.read_content .read_more');
        $(document).on({
            click: function () {
                $(this).hide();
                $(readContent).find('.read_more').show();
                $('.truncate').show()
                $('.notruncate').hide();
            }
        }, '.read_content .read_less');
    }
    if (Cookies.get('bannertop')) {
        $('#bannertop_slide').remove();
        $('#header').addClass('nobannertop')
        $('#category_header_filter_top').addClass('nobannertopFilter')
    }
    if ($('#bannertop_slide').length > 0) {
        new Splide('#bannertop_slide', {
            direction: 'ttb',
            type: 'loop',
            height: '56px',
            arrows: false,
            pagination: false,
            autoplay: true,
            interval: 5000,
            pauseOnHover: true
        }).mount();
    }
});

$('.close_element').on('click', function () {
    $(this).parent().fadeOut(500);
    $('#header').addClass('nobannertop');
    Cookies.set('bannertop', true, {expires: 1, path: '/'});
});

$('#menu-icon').on('click', () => {
    $('body').addClass('modal-open');
    toggleMobileMenu();
});

function toggleMobileMenu() {
    $('#header').toggleClass('is-open');
    if ($('#_mobile_top_menu').is(':visible')) {
    } else {
        $('#_mobile_top_menu').css('display', 'block');
    }
}